import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
      title: 'INÍCIO',
      url: '/folder/folder.module',
      icon: 'home'
    },
    {
      title: 'CONTEÚDO TEÓRICO',
      url: '/tec-tc',
      icon: 'book' 
    },
    {
      title: 'CONTEÚDO PRÁTICO',
      url: '/conteudo-pratico',
      icon: 'today'
    }
    ,
    {
      title: 'CRÉDITOS',
      url: '/creditos-app',
      icon: 'medal'
    },
    {
      title: 'SUGESTÕES',
      url: '/sugestoes-app',
      icon: 'chatbubbles'
    },
    {
      title: 'TERMOS DE USO',
      url: '/termos-de-uso',
      icon: 'list'
    }
    ,
    {
      title: 'TUTORIAL',
      url: '/tutorial',
      icon: 'school'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {}
}
